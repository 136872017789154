@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
/**************************[ BASE ]**************************/
body {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u, div {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:24px;
	letter-spacing:3px;
	font-family: 'Oswald', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:16px;
	font-weight:bold;
	letter-spacing:0;
	line-height:20px;
	font-family: 'Oswald', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Oswald', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Oswald', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	font-family: 'Oswald', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h3, h3.product-name, h4, h5
{
	font-family: 'Oswald', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}
.col-main.grid12-12.container {
	margin-left: auto;
	margin-right: auto;
	float: none;
	display: block;
}
i.fa {
	font-style: normal;
}
@media only screen and (min-width: 960px) {
	.show-below-960, .hide-above-960 {
		display: none !important;
	}
}
/**************************[ HEADER ]**************************/
.xm-grid-header > .container {
	max-width: none;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Roboto', sans-serif;
}
.header a:hover {
	color: #fff;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode:not(.fa-bars), .userName {
	color: #fff !important;
}

/* Logo */


/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Roboto', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #fff;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}
.module-search.flyout.fly-right .form-search .input-text {
	height: 36px !important;
	line-height: 36px !important;
	padding: 15px 0 !important;
	color: transparent;
}
.module-search.flyout.fly-right .form-search .input-text:hover, .module-search.flyout.fly-right .form-search .input-text:focus {
	width: 150px !important;
	padding-left: 15px !important;
	position: relative;
	z-index: 1000;
	color: #000;
}
.module-search.flyout.fly-right .form-search .button-search {
	height: 36px;
	line-height: 36px;
}
.module-search .form-search .button-search .fa {
	font-size: 20px !important;
	padding: 8px !important;
	color: #fff !important;
}
.desktop-search-column {
	margin-top: 30px;
	margin-bottom: 30px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #006dcc;
}
.dropdown-toggle .value {
	color: #5f6369;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}
.std p.welcome-msg {
	margin: 0;
}
.desktop-main-header-container #mini-cart .fa.fa-shopping-cart {
	font-size: 30px;
}
.desktop-main-header-container #mini-cart .hide-below-960 {
	height: 30px;
	line-height: 30px;
}
.desktop-cart-column {
	margin-top: 20px;
	margin-bottom: 20px;
}
.desktop-cart-column .dropdown-toggle.cover > div {
	padding: 10px 0 0 0;
}
/* Menu */
.custom-freeshipping-note a, .links > li > a {
	color: #fff;
	font-size: 14px;
	font-family: 'DIN', Helvetica, sans-serif;
	font-weight: bold;
}
.links > li > a:hover {
	background-color: transparent;
	color: #fff !important;
}
.nav-regular li.level0 > a > span {
	color: #000;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #006dcc;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #006dcc;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #006dcc;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #006dcc;
	border-right-color: #006dcc;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button {
	margin-top: 10px;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #009e58 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #009e58 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

.desktop-main-header-container #nav.nav-regular {
	margin: 0;
    justify-content: space-between;
    flex-wrap: wrap;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
}
@media only screen and (min-width: 1200px) {
	.desktop-main-header-container .nav.container {
		margin: 0;
		width: 100%;
		max-width: none;
	}
	.desktop-main-header-container #nav.nav-regular {
		display: flex;
	}
	.desktop-main-header-container .nav-column.grid12-8 {
		width: 66.67%;
		margin: 0;
	}
	.desktop-main-header-container #nav.nav-regular li.level0 > a {
		line-height: 96px;
		padding: 0;
	}
	#inline-search-results {
		width: 400% !important;
		left: -150%;
	}
}
@media only screen and (min-width: 960px) and (max-width: 1199px) {
	.desktop-main-header-container #nav.nav-regular li.level0 > a {
		line-height: 48px;
		padding: 0 7px;
	}
	#inline-search-results {
		width: 500% !important;
		left: -250%;
	}
}
@media only screen and (min-width: 960px) {
	.header-top-container {
		background-color:#009e58;
		text-transform: uppercase;
	}
	#mini-cart .feature-icon-hover > a {
		text-align: center;
	}
	.logo img {
		max-width: 170px;
	}
}
@media only screen and (max-width: 959px) {
	#header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 9999;
	}
	.mobile-header-container.col-main {
		margin-bottom: 0;
	}
	.header .logo {
		max-width: 100px;
	}
	.nav-search.fly-out .form-search .button {
        color: #fff;
        top: 10px;
        right: 10px;
    }
	.nav-search.fly-out .form-search .button-search {
        right: 45px;
    }
	.search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }
    .search-wrapper-mobile .form-search input, .header .caret, .nav-search .input-text {
        display: none;
    }
    .nav-search .button {
        height: 36px;
    }
	.nav-search .button .fa.fa-search {
		font-size: 18px;
	}
	.nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100vw !important;
        background-color: #fff;
    }
    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }
    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }
    .nav-search.fly-out .form-search .button {
        color: #fff;
        top: 10px;
        right: 10px;
    }
    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }
    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }
    .nav-search.fly-out .form-search .button-close {
        display: block;
    }
	#mini-cart .feature-icon-hover .hide-below-960 {
		display: block !important;
	}
	.nav-cart .feature-icon-hover .empty {
		display: none;
	}
	.header .fa.fa-shopping-cart {
		top: 0;
		left: 0;
		line-height: 36px;
	}
	.mobile-cart-column, .mobile-nav-column {
		height: 60px;
	}
	.header .mobile-cart-column .dropdown, .header .mobile-cart-column .dropdown *, .mobile-nav-column .module-mmenu.mmenu-wrapper.mmenu-category-wrapper, .mobile-nav-column .module-mmenu.mmenu-wrapper.mmenu-category-wrapper *, #root-wrapper .mobile-nav-column .module-mmenu.mmenu-wrapper.mmenu-category-wrapper span.fa, .menu-icon.fa.fa-vcard::before {
		max-height: none;
		line-height: 60px;
		height: 60px;
	}
	/* account menu icon */
	.menu-icon.fa.fa-vcard {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.mobile-nav-column .module-mmenu.mmenu-wrapper.mmenu-category-wrapper a.mh-category-btn {
		display: block;
	}
	#root-wrapper .mobile-nav-column .module-mmenu.mmenu-wrapper.mmenu-category-wrapper span.fa {
		padding: 0;
		margin: 0;
	}
	#mini-cart .empty {
		display: none;
	}
}
@media only screen and (max-width: 959px) and (min-width: 768px) {
	.header .item-right.mobile-cart-column {
		float: left;
	}
	.mobile-nav-column {
		text-align: right;
	}
}
@media only screen and (max-width: 767px) {
	
	#header .grid12-1, #header .grid12-10, #header .grid12-11, #header .grid12-12, #header .grid12-2, #header .grid12-3, #header .grid12-4, #header .grid12-5, #header .grid12-6, #header .grid12-7, #header .grid12-8, #header .grid12-9 {
		margin-left: 1%;
		margin-right: 1%;
	}
	#header .grid12-12 {
		width: 98% !important;
	}
	#header .grid12-11 {
		width: 89.67% !important;
	}
	#header .grid12-10 {
		width: 81.33% !important;
	}
	#header .grid12-9 {
		width: 73% !important;
	}
	#header .grid12-8 {
		width: 64.67% !important;
	}
	#header .grid12-7 {
		width: 56.33% !important;
	}
	#header .grid12-6 {
		width: 48% !important;
	}
	#header .grid12-5 {
		width: 39.67% !important;
	}
	#header .grid12-4 {
		width: 31.33% !important;
	}
	#header .grid12-3 {
		width: 23% !important;
	}
	#header .grid12-2 {
		width: 14.66% !important;
	}
	#header .grid12-1 {
		width: 6.33% !important;
	}
	#header .grid12-12.no-gutter {
		margin-left: 0;
		margin-right: 0;
		width: 100% !important;	
	}
	.mobile-header-container .module-logo.logo-wrapper.header {
		position: relative;
		height: 55px;
	}
	.mobile-header-container a.logo {
		position: absolute;
		left:0;
		top: 5px;
	}
	.form-search .button-search {
		top: 10px;
	}
}
/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #000;
	letter-spacing: 3px;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
button.button {
	padding: 0 10px;
}
.product-view .btn-cart span, button.button, button.button span {
	background-color: #009e58;
	color: #fff;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	border-radius: 0;
	padding: 0;
}
.xm-grid-product .add-cart-wrapper {
	padding-top: 10px;
}
button, .button, .button a, button a {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}
button.button:not(.button-search):not(.search-btn-quickorder) {
	border: 1px solid #009e58;
}
button.button span {
	font-size: 16px;
	width: 100%;
}
.products-list button.button span, .products-grid button.button span {
	width: 100%;
}
.products-grid button.button {
	width: calc(46% - 2px);
	margin: 0 2%;
}
.products-list .add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover, .button:hover span {
	background-color: #009e58 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
@media only screen and (max-width: 959px) {
	.switch-cat-view-container {
		width: 100%;
		float: none;
	}
	.products-grid button.button {
		width: calc(96% - 2px);
	}
	.products-grid button.button.more-info {
		margin-top: 4px;
	}
	.main-container {
		padding-top: 60px;
	}
	.hide-below-960 {
		display: none !important;
	}
	.grid12-12, .grid-full {
		overflow-x: hidden;
	}
}
@media only screen and (max-width: 767px) {
	.products-list .product-image img, .products-list .button, .products-list .item-code {
		display: block;
		margin: auto;
	}
	.products-list .button {
		max-width: none;
	}
	.products-list .item-code, .products-list .product-name, .products-list .price-box {
		text-align: center;
	}
}
/**************************[ Footer ]**************************/
.footer-top-container {
	padding-top: 10px;
}
.footer-top-container .section.clearer {
	display: flex;
	justify-content: space-between;
}
.footer-top-container div[id*="column"]:not(.footer-logo-column)
{
	width: auto;
}
.footer-top.footer::after, .footer-top.footer .clearer::after {
	content: none;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.top-footer-container {
	border-bottom: 1px solid #006dcc;
}
.footer-social-column .fa {
	font-size: 20px;
	margin-left: 24px;
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
	.footer-links-column .grid12-3 {
		width: 31.33% !important;
	}
}
@media only screen and (max-width: 767px) {
	.footer-logo-column.grid12-2 {
		width: 30% !important;
	}
	.footer-social-column {
		width: 70% !important;
	}
	.footer-logo-column, .footer-social-column {
		float: left !important;
	}
	.footer .item-right {
		margin-left: 0;
	}
}
/************************** MICS **************************/
.inline-search-results-quickorder .highlight {
	background-color: #009e58 !important;
}
.page-type-user .isPublic button[title="Create an Account"].button span span {
	text-indent: -9999px;
}
.page-type-user .isPublic button[title="Create an Account"].button span span::after {
	text-indent: 0;
	content: "Request an Account";
	float: left;
}
@media only screen and (max-width: 959px) {
	.page-type-user table#shopping-cart-table {
		border: 1px solid rgba(0,0,0,0.1);
		width: calc(100vw);
		overflow: auto;
		display: block;
	}
	.page-type-user table#shopping-cart-table tr {
		border-bottom: 1px solid rgba(0,0,0,0.05);
	}
	.page-type-user table#shopping-cart-table tr th, .page-type-user table#shopping-cart-table tr td {
		border-right: 1px solid rgba(0,0,0,0.08);
		transition: box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
    	box-shadow: inset 0 0 0 0 transparent;
	}
	.page-type-user table#shopping-cart-table tr td.auto_cart_buttons {
		width: 150px;
		display: block;
	}
}
